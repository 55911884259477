// @ts-nocheck
/* eslint-disable */

import * as THREE from 'three'
import * as React from 'react'
import { Canvas, useLoader, useThree } from '@react-three/fiber'
import { OrbitControls, useGLTF, Edges, Environment, useTexture, MeshRefractionMaterial, Stats, AdaptiveDpr, AdaptiveEvents, Html, MeshReflectorMaterial, useFBX } from '@react-three/drei'
import { MeshPhysicalMaterial, Texture, Vector3 } from 'three';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import pricing from '../assets/pricing.json'
import { useControls } from 'leva';
import { ErrorBoundary } from 'react-error-boundary'
import { MeshBVHUniformStruct } from "@react-three/drei/node_modules/three-mesh-bvh"
import emailjs from '@emailjs/browser';

import { designerState } from './Main';
import { RGBELoader } from 'three-stdlib/loaders/RGBELoader';
import { GLTF } from 'three-stdlib';

interface metal {
	[key: string]: colorObject;
}

interface colorObject {
	r: number,
	g: number,
	b: number
};

export interface emailData {
	to: string,
	cc: string,
	bcc: string,
	subject: string,
	body: string,
	associate_firstname: string,
	associate_lastname: string,
	associate_email: string
}

export class ModelLoader extends React.Component<{}, any> {

	metal: metal;
	orbitControls: undefined;
	ambLight: undefined;
	pointLight1: undefined;
	pointLight2: undefined;
	pointLight3: undefined;
	pointLight4: undefined;
	urlParams: URLSearchParams;
	private url: string;
	private price: number;
	private test: boolean;
	private debug: boolean;

	constructor(props: any) {
		super(props);

		this.debug = true;
		this.metal = {};
		this.metal['platinum'] = { r: 0.39, g: 0.43, b: 0.51 };
		this.metal['gold'] = { r: 0.68, g: 0.42, b: 0.13 };
		this.metal['rose'] = { r: 0.72, g: 0.32, b: 0.23 };
		// this.metal['platinum'] = { r: 0.19, g: 0.23, b: 0.31 };
		// this.metal['gold'] = { r: 0.48, g: 0.22, b: 0.03 };
		// this.metal['rose'] = { r: 0.52, g: 0.12, b: 0.13 };
		this.price = 0;

		let decodedURL = decodeURIComponent(window.location.toLocaleString());

		this.urlParams = new URL(decodedURL).searchParams;

		this.state = {
			price: 0,
			diamond: 'Round',
			size: 3,
			band: "Solitaire",
			pave1: "",
			paveMilgrain: "",
			paveEngraving: "",
			pave2: "",
			shankSplit: "",
			channelStyle: "",
			milgrain: "",
			engraving: "",
			basket: "Floating",
			bezel: "",
			halo: "",
			haloMilgrain: "",
			prongStyle: "ClassicClaw",
			sideStones: "",
			sideStonesBand: "",
			sideStonesBasket: "",
			shoulder: "Plain",
			colorSelected: 'platinum',
			orientationNorth: false,
			scene: undefined,
			camera: undefined,
			controls: undefined,
			sketch: false,
			error: '',
			firstName: '',
			lastName: '',
			email: '',
			fullscreen: false
		};
		//this.url = '?';
		this.url = localStorage.getItem('url');
		this.urlParams = new URL(location.protocol + '//' + location.host + location.pathname + this.url).searchParams;
		this.test = false;
	}


	updateURL() {
		//const baseURL = (window.location.pathname, '');
		//console.log(this.state);
		this.url = "?";
		this.url += "price=" + this.state.price;
		this.url += "&diamond=" + this.state.diamond;
		this.url += "&band=" + this.state.band;
		this.url += "&pave1=" + this.state.pave1;
		this.url += "&paveMilgrain=" + this.state.paveMilgrain;
		this.url += "&paveEngraving=" + this.state.paveEngraving;
		this.url += "&pave2=" + this.state.pave2;
		this.url += "&shankSplit=" + this.state.shankSplit;
		this.url += "&channelStyle=" + this.state.channelStyle;
		this.url += "&milgrain=" + this.state.milgrain;
		this.url += "&engraving=" + this.state.engraving;
		this.url += "&basket=" + this.state.basket;
		this.url += "&bezel=" + this.state.bezel;
		this.url += "&halo=" + this.state.halo;
		this.url += "&haloMilgrain=" + this.state.haloMilgrain;
		this.url += "&prongStyle=" + this.state.prongStyle;
		this.url += "&sideStones=" + this.state.sideStones;
		this.url += "&sideStonesBand=" + this.state.sideStonesBand;
		this.url += "&sideStonesBasket=" + this.state.sideStonesBasket;
		this.url += "&colorsTab=" + this.state.colorsTab;
		this.url += "&colorSelected=" + this.state.colorSelected;
		this.url += "&size=" + this.state.size;
		this.url += "&orientation=" + this.state.orientation;
		this.url += "&associate_firstname=" + this.state.firstName;
		this.url += "&associate_lastname=" + this.state.lastName;
		this.url += "&associate_email=" + this.state.email;
		this.url += this.state.fullscreen ? "&fullscreen" : "";
		//console.log("Fullscreen is:::::::::::::::::", this.state.fullscreen);
		history.replaceState('url', "", encodeURIComponent(this.url));
		localStorage.setItem('url', this.url);
	}

	componentDidMount() {
		this.initURLParams();
		this.calculatePrice();
		document.addEventListener("keydown", (event) => {
			if (event.key == '1')
				this.sketch(1);
			else if (event.key == '2')
				this.sketch(2);
			else if (event.key == '3')
				this.sketch(3);
		});

		setTimeout(() => {
			if (this.state.sketch) {

				this.metal['platinum'] = { r: 0.19, g: 0.23, b: 0.31 };
				this.metal['gold'] = { r: 0.48, g: 0.22, b: 0.03 };
				this.metal['rose'] = { r: 0.52, g: 0.12, b: 0.13 };

				let ambLight = this.ambLight as unknown as THREE.AmbientLight;
				let pointLight1 = this.pointLight1 as unknown as THREE.PointLight;
				let pointLight2 = this.pointLight2 as unknown as THREE.PointLight;
				let pointLight3 = this.pointLight3 as unknown as THREE.PointLight;
				let pointLight4 = this.pointLight4 as unknown as THREE.PointLight;

				if (this.ambLight) ambLight.intensity = 0.5;
				if (this.pointLight1) {
					pointLight1.intensity = 1;
					pointLight1.position.set(-50, -40, -10);
				}
				if (this.pointLight2) {
					pointLight2.intensity = 1;
					pointLight2.position.set(50, -40, -10);
				}
				if (this.pointLight3) {
					pointLight3.intensity = 1;
					pointLight3.position.set(-50, 40, 10);
				}
				if (this.pointLight4) {
					pointLight4.intensity = 1;
					pointLight4.position.set(50, 40, 10);
				}
				this.setState({ sketch: true });
			}
		}, 1000);
	}

	copyLink() {
		navigator.clipboard.writeText(location.protocol + '//' + location.host + location.pathname + this.url.replace(/ /g, '%20') + '&fullscreen');
	}

	sendEmail(data: emailData, callback: Function) {

		const serviceID = "service_xv360ne";
		const templateID = "3d-ring-designer-app";
		const publicKey = "user_VdYyVJ5pMO4CQdUPQUNak";

		let exportConfigObject = "<p>The selected options are: <br/> <br/><b>Metal: </b> " + this.state.colorSelected + "<br/>" +
			"<b>Price: </b> " + this.state.price + "<br/>" +
			"<b>Stone: </b> " + this.state.diamond + "<br/>" +
			"<b>Size: </b> " + this.state.size + "<br/>" +
			"<b>Band: </b> " + this.state.band + "<br/>" +
			"<b>Pave: </b> " + this.state.pave1 + "<br/>" +
			"<b>Pave Milgrain: </b> " + this.state.paveMilgrain + "<br/>" +
			"<b>Pave Engraving: </b> " + this.state.paveEngraving + "<br/>" +
			"<b>Shank split: </b> " + this.state.shankSplit + "<br/>" +
			"<b>Channel Style: </b> " + this.state.channelStyle + "<br/>" +
			"<b>Milgrain: </b> " + this.state.milgrain + "<br/>" +
			"<b>Engraving: </b> " + this.state.engraving + "<br/>" +
			"<b>Basket: </b> " + this.state.basket + "<br/>" +
			"<b>Bezel: </b> " + this.state.bezel + "<br/>" +
			"<b>Halo: </b> " + this.state.halo + "<br/>" +
			"<b>Halo Milgrain: </b> " + this.state.haloMilgrain + "<br/>" +
			"<b>Prong Style: </b> " + this.state.prongStyle + "</p>";

		function encodeHTML(str: string) {
			return str.replace(/\r\n/g, '<br />')
				.replace(/\n/g, '<br />');
		}

		const subjectEncoded = data.subject;
		const bodyEncoded = encodeHTML(data.body);




		const emailTemplate = {
			to_email: data.to,
			cc: data.cc,
			bcc: data.bcc,
			subject: subjectEncoded,
			body: bodyEncoded,
			link: location.protocol + '//' + location.host + location.pathname + encodeURIComponent(this.url) + '&fullscreen',
			config: exportConfigObject,
			associated_first: this.state.firstName,
			associated_last: this.state.lastName,
			associated_email: this.state.email
		}

		let payload = JSON.stringify(emailTemplate);

		let xhr = new XMLHttpRequest();
		xhr.onload = function () {
			if (this.status == 200) {
			}
		}
		xhr.open('POST', 'https://webhooks.workato.com/webhooks/rest/db2c0765-2515-4868-aff2-b461ed5e9877/3d-ring-designer-email-to-emailjs', true);
		xhr.setRequestHeader("Content-type", "application/json");
		xhr.send(payload);
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				callback();
			}
		};


		// emailjs.send(serviceID, templateID, emailTemplate, publicKey)
		// 	.then((response) => {
		// 		callback();
		// 	}, (err) => {
		// 		callback();
		// 	});
	}

	initURLParams() {

		if (this.urlParams.getAll("price").length > 0) {
			this.setState({ price: parseInt(this.urlParams.getAll("price")[0]) });
		}

		if (this.urlParams.getAll("sketch").length > 0) {
			this.setState({ sketch: true });
		}

		if (this.urlParams.getAll("diamond").length > 0) {
			let diamondName = this.mapName(this.urlParams.getAll("diamond")[0]);
			if (diamondName === "") diamondName = this.urlParams.getAll("diamond")[0];
			this.setState({ diamond: diamondName });
		}
		if (this.urlParams.getAll("size").length > 0) {
			this.setState({ size: this.urlParams.getAll("size")[0] });
		}
		if (this.urlParams.getAll("band").length > 0) {
			this.setState({ band: this.urlParams.getAll("band")[0] });
		}
		if (this.urlParams.getAll("pave1").length > 0) {
			this.setState({ pave1: this.urlParams.getAll("pave1")[0] });
		}
		if (this.urlParams.getAll("paveMilgrain").length > 0) {
			this.setState({ paveMilgrain: this.urlParams.getAll("paveMilgrain")[0] });
		}
		if (this.urlParams.getAll("paveEngraving").length > 0) {
			this.setState({ paveEngraving: this.urlParams.getAll("paveEngraving")[0] });
		}
		if (this.urlParams.getAll("pave2").length > 0) {
			this.setState({ pave2: this.urlParams.getAll("pave2")[0] });
		}
		if (this.urlParams.getAll("shankSplit").length > 0) {
			this.setState({ shankSplit: this.urlParams.getAll("shankSplit")[0] });
		}
		if (this.urlParams.getAll("channelStyle").length > 0) {
			this.setState({ channelStyle: this.urlParams.getAll("channelStyle")[0] });
		}
		if (this.urlParams.getAll("basket").length > 0) {
			this.setState({ basket: this.urlParams.getAll("basket")[0] });
		}
		if (this.urlParams.getAll("bezel").length > 0) {
			this.setState({ bezel: this.urlParams.getAll("bezel")[0] });
		}
		if (this.urlParams.getAll("engraving").length > 0) {
			this.setState({ engraving: this.urlParams.getAll("engraving")[0] });
		}
		if (this.urlParams.getAll("halo").length > 0) {
			this.setState({ halo: this.urlParams.getAll("halo")[0] });
		}
		if (this.urlParams.getAll("haloMilgrain").length > 0) {
			this.setState({ haloMilgrain: this.urlParams.getAll("haloMilgrain")[0] });
		}
		if (this.urlParams.getAll("prongStyle").length > 0) {
			this.setState({ prongStyle: this.urlParams.getAll("prongStyle")[0] });
		}
		if (this.urlParams.getAll("sideStones").length > 0) {
			this.setState({ sideStones: this.urlParams.getAll("sideStones")[0] });
		}
		if (this.urlParams.getAll("sideStonesBand").length > 0) {
			this.setState({ sideStonesBand: this.urlParams.getAll("sideStonesBand")[0] });
		}
		if (this.urlParams.getAll("sideStonesBasket").length > 0) {
			this.setState({ sideStonesBasket: this.urlParams.getAll("sideStonesBasket")[0] });
		}
		if (this.urlParams.getAll("shoulder").length > 0) {
			this.setState({ shoulder: this.urlParams.getAll("shoulder")[0] });
		}
		if (this.urlParams.getAll("colorSelected").length > 0) {
			this.setState({ colorSelected: this.urlParams.getAll("colorSelected")[0] });
		}

		if (this.urlParams.getAll("associate_firstname").length > 0) {
			this.setState({ firstName: this.urlParams.getAll("associate_firstname")[0] });
		}

		if (this.urlParams.getAll("associate_lastname").length > 0) {
			this.setState({ lastName: this.urlParams.getAll("associate_lastname")[0] });
		}

		if (this.urlParams.getAll("associate_email").length > 0) {
			this.setState({ email: this.urlParams.getAll("associate_email")[0] });
		}

		if (this.urlParams.getAll("fullscreen").length > 0) {
			this.setState({ fullscreen: true });
		}
	}

	setCompleteState(changedState: designerState): void {

		changedState.shape = this.mapName(changedState.shape);
		if (changedState.shape == '') changedState.shape = 'Round';
		if (changedState.basket == '') changedState.basket = "Floating";
		if (changedState.prongStyle == '') changedState.prongStyle = "ClassicClaw";
		if (changedState.shoulder == '') changedState.shoulder = "Plain";
		if (changedState.colorSelected == '') changedState.colorSelected = 'platinum';
		else if (changedState.colorSelected == 'Platinum') changedState.colorSelected = 'platinum';
		else if (changedState.colorSelected == 'Yellow Gold') changedState.colorSelected = 'gold';
		else if (changedState.colorSelected == 'Rose Gold') changedState.colorSelected = 'rose';
		if (changedState.reset == true) this.resetCamera();

		this.setState({
			price: changedState.price,
			diamond: changedState.shape,
			size: 3,
			band: changedState.band,
			pave1: changedState.pave1,
			paveMilgrain: changedState.paveMilgrain,
			paveEngraving: changedState.paveEngraving,
			pave2: changedState.pave2,
			shankSplit: changedState.shankSplit,
			channelStyle: changedState.channelStyle,
			milgrain: changedState.milgrain,
			engraving: changedState.engraving,
			basket: changedState.basket,
			bezel: changedState.bezel,
			halo: changedState.halo,
			haloMilgrain: changedState.haloMilgrain,
			prongStyle: changedState.prongStyle,
			sideStones: "",
			sideStonesBand: "",
			sideStonesBasket: "",
			shoulder: changedState.shoulder,
			colorSelected: changedState.colorSelected,
			orientationNorth: changedState.orientationNorth,
		});

		//console.log("Setting state: ", changedState);
	}

	resetCamera() {
		const camera = this.state.camera;

		if (window.innerWidth > 1024) {
			camera.position.set(0, 80, 0);
			camera.zoom = 24;
		}
		else if (window.innerHeight > 500 || screen.orientation.type != "landscape-primary") {
			camera.position.set(0, 80, 0);
			camera.zoom = 15;
		}
	}

	changeModel(name: string) {
		let diamondName = this.mapName(name);
		if (diamondName === "") diamondName = name;
		this.setState({ diamond: diamondName });
	}

	increaseSize(callback: (size: number) => void) {
		if (this.state.size < 6) {
			this.setState({ size: parseInt(this.state.size) + 1 });
			callback(parseInt(this.state.size) + 1);
		}
	}

	decreaseSize(callback: (size: number) => void) {
		if (this.state.size > 1) {
			this.setState({ size: this.state.size - 1 });
			callback(this.state.size - 1);
		}
	}

	getSize() {
		return this.state.size;
	}

	changeShank(number: number) {
		if (number == 1) this.setState({ colorSelected: 'platinum' });
		if (number == 2) this.setState({ colorSelected: 'gold' });
		if (number == 3) this.setState({ colorSelected: 'rose' });
	}

	changeOrientation(north: boolean) {
		this.setState({ orientationNorth: north })
	}

	changeBasket(name: string) {
		// let diamondName = this.state.diamond;
		// let size = this.state.size;

		// if (name == 'Bezel') diamondName = 'Cushion_Bezel';
		// else if (name == 'UShape') diamondName = 'CushionU';
		// else if (name == 'Classic') diamondName = 'CushionClassic';

		this.setState({ basket: name })
	}

	changeBand(name: string) {
		//if (name === 'Pave') this.setState({ diamond: 'Pave' })
		if (name == "Solitaire") {
			this.setState({ pave1: '' });
		}
		this.setState({ band: name })
	}

	changeChannel(name: string) {
		this.setState({ channelStyle: name });
	}

	changeMilgrain(name: string) {
		this.setState({ milgrain: name });
	}

	changeEngraving(name: string) {
		this.setState({ engraving: name });
	}

	changePave1(name: string) {
		this.setState({ pave1: name });
	}

	changePaveMilgrain(name: string) {
		this.setState({ paveMilgrain: name });
	}

	changePaveEngraving(name: string) {
		this.setState({ paveEngraving: name });
	}

	changePave2(name: string) {
		this.setState({ pave2: name });
	}

	changeShankSplit(name: string) {
		this.setState({ shankSplit: name });
	}

	changeBezel(name: string) {
		this.setState({ bezel: name });
	}

	changeHalo(name: string) {
		this.setState({ halo: name });
	}

	changeHaloMilgrain(name: string) {
		this.setState({ haloMilgrain: name });
	}

	changeProng(name: string) {
		this.setState({ prongStyle: name });
	}

	changeSidestone(name: string) {
		this.setState({ sideStones: name });
	}

	changeSidestoneBand(name: string) {
		this.setState({ sideStonesBand: name });
	}

	changeSidestoneBasket(name: string) {
		this.setState({ sideStonesBasket: name });
	}

	tryOn(callback) {
		this.screenshot(true, callback);
	}

	calculatePrice() {
		let price = this.state.price;

		pricing.diamonds.filter((item) => {
			if (item.size == this.state.size)
				price *= item.price;
		});

		pricing.band.filter((item) => {
			if (item.name === this.state.band)
				price += item.price;
		});

		pricing.pave1.filter((item) => {
			if (item.name === this.state.pave1)
				price += item.price;
		});

		pricing.paveMilgrain.filter((item) => {
			if (item.name === this.state.paveMilgrain)
				price += item.price;
		});

		pricing.paveEngraving.filter((item) => {
			if (item.name === this.state.paveEngraving)
				price += item.price;
		});

		pricing.pave2.filter((item) => {
			if (item.name === this.state.pave2)
				price += item.price;
		});

		pricing.shankSplit.filter((item) => {
			if (item.name === this.state.shankSplit)
				price += item.price;
		});

		pricing.channelStyle.filter((item) => {
			if (item.name === this.state.channelStyle)
				price += item.price;
		});

		pricing.milgrain.filter((item) => {
			if (item.name === this.state.milgrain)
				price += item.price;
		});

		pricing.engraving.filter((item) => {
			if (item.name === this.state.engraving)
				price += item.price;
		});

		pricing.basket.filter((item) => {
			if (item.name === this.state.basket)
				price += item.price;
		});

		pricing.bezel.filter((item) => {
			if (item.name === this.state.bezel)
				price += item.price;
		});

		pricing.halo.filter((item) => {
			if (item.name === this.state.halo)
				price += item.price;
		});

		pricing.haloMilgrain.filter((item) => {
			if (item.name === this.state.haloMilgrain)
				price += item.price;
		});

		pricing.prongStyle.filter((item) => {
			if (item.name === this.state.prongStyle)
				price += item.price;
		});

		pricing.sideStones.filter((item) => {
			if (item.name === this.state.sideStones)
				price += item.price;
		});

		pricing.sideStonesBand.filter((item) => {
			if (item.name === this.state.sideStonesBand)
				price += item.price;
		});

		pricing.sideStonesBasket.filter((item) => {
			if (item.name === this.state.sideStonesBasket)
				price += item.price;
		});

		pricing.metal.filter((item) => {
			if (item.name === this.state.colorSelected)
				price += item.price;
		});

		this.price = price;
	}

	getPrice() {
		return this.price;
	}

	screenshot(download: boolean, callback) {
		const gl = this.state.scene;
		const camera = this.state.camera;

		const curPos = new THREE.Vector3;
		//const curLook = new THREE.Vector3;

		curPos.copy(camera.position);
		//curLook.copy(camera.lookAt);
		const controls = this.orbitControls as unknown as any;
		controls.enabled = false;

		gl.setClearColor(0xffffff, 0);
		camera.position.set(0, 350, 0.3);
		camera.lookAt(0, 0, 0.3);

		if (window.innerWidth > 1024) {
			camera.position.set(0, 80, 0);
			camera.zoom = 13.5;
		}
		else if (window.innerHeight > 500 || screen.orientation.type != "landscape-primary") {
			camera.position.set(0, 80, 0);
			camera.zoom = 6;
		}

		camera.updateProjectionMatrix();
		const scope = this;

		setTimeout(() => {
			if (!download) {
				const link = document.createElement('a');
				link.setAttribute('download', 'canvas.png');
				link.setAttribute('href', gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'));
				link.click();
				//scope.sketch();
			}
			else {
				var image = new Image();
				image.onload = function () {
					var interCanvas = document.createElement('canvas');
					const aspect = image.width / image.height;

					interCanvas.width = 1350;
					interCanvas.height = 1350 / aspect;
					const interCtx = interCanvas.getContext("2d");

					if (interCtx) interCtx.drawImage(image, 0, 0, image.width, image.height, 0, 0, interCanvas.width, interCanvas.height);

					var image2 = new Image();
					image2.onload = function () {
						var canvas = document.createElement('canvas');
						canvas.width = 512;
						canvas.height = 512;
						var ctx = canvas.getContext("2d");

						if (ctx) ctx.drawImage(image2, ((image2.width / 2) - 256), ((image2.height / 2) - 256), 512, 512, 0, 0, 512, 512);

						let xhr = new XMLHttpRequest();
						xhr.onload = function () {
							if (this.status == 200) {
							}
						}
						const sku = Math.ceil(Math.random() * 10000);
						//xhr.open('POST', 'https://webhook.site/d10eef7d-2dc2-4731-bf23-776fa178d261', true);
						xhr.open('POST', 'https://webhooks.workato.com/webhooks/rest/db2c0765-2515-4868-aff2-b461ed5e9877/create-try-on-png', true);
						xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
						xhr.send('sku=' + sku + '&image=' + encodeURIComponent(canvas.toDataURL()));
						scope.setState({ loader: true });

						setTimeout(() => {
							gl.setClearColor(0xffffff, 0);
							controls.enabled = true;
							camera.position.set(curPos.x, curPos.y, curPos.z);
							camera.zoom = 24;
							window.open("https://kwiat.com/illumix-view?sku=" + sku, "_blank");
							scope.setState({ loader: false });
							setTimeout(() => {
								gl.setClearColor(0xffffff, 0);
								controls.enabled = true;
								camera.position.set(curPos.x, curPos.y, curPos.z);
								camera.zoom = 24;
								callback();
							}, 150);
						}, 500);
					};
					image2.src = interCanvas.toDataURL();
				};
				image.src = gl.domElement.toDataURL();


			}
		}, 3000);
	}

	sketch(key: number) {

		//console.log("Downloading now!");
		const gl = this.state.scene;
		const camera = this.state.camera;

		const curPos = new THREE.Vector3;
		curPos.copy(camera.position);

		const controls = this.orbitControls as unknown as any;
		if (controls) controls.enabled = false;

		gl.setClearColor(0xffffff, 0);

		//Shape_Color_Band_Basket_Prong_Pave2_Bezel_Halo
		let name = this.state.diamond + "_" + this.state.colorSelected + "_" + this.state.band + "_" + this.state.basket + "_" + this.state.prongStyle + "_" + this.state.pave2 + "_" + this.state.bezel + "_" + this.state.halo + "_" + this.state.milgrain + ".png";
		//console.log(name);

		if (key == 1) {
			camera.position.set(0, 350, 0.3);
			camera.lookAt(0, 0, 0.3);
			camera.zoom = 17;
			//name = "Angle1_" + name;
		}
		else if (key == 2) {
			camera.position.set(350, 12, 0);
			camera.lookAt(0, 12, 0);
			camera.zoom = 17;
			//name = "Angle2_" + name;
		}
		else if (key == 3) {
			camera.position.set(0, 12, 350);
			camera.lookAt(0, 12, 350);
			camera.zoom = 17;
			//name = "Angle3_" + name;
		}
		camera.updateProjectionMatrix();

		const scope = this;

		setTimeout(() => {
			const link = document.createElement('a');
			link.setAttribute('download', name);
			link.setAttribute('href', gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'));
			link.click();
			setTimeout(() => {
				gl.setClearColor(0xffffff, 0);
				if (controls) controls.enabled = true;
				camera.position.set(curPos.x, curPos.y, curPos.z);
				camera.zoom = 15;
			}, 1000);
		}, 100);
	}

	Diamond({ ...props }) {

		//console.log(props.model);
		if (!props.model || props.model == '')
			return (<></>);

		// if (props.model.includes('Fred')) {
		// 	props.model = "Fred_1ct.fbx";
		// }
		// else if (props.model.includes('Oval')) {
		// 	props.model = "Oval_1ct.fbx";
		// }
		// else if (props.model.includes('Radiant')) {
		// 	props.model = "Radiant_1ct.fbx";
		// }
		// else if (props.model.includes('Round')) {
		// 	props.model = "Round_3ct.fbx";
		// }
		// else if (props.model.includes('Emerald')) {
		// 	props.model = "Emerald_3ct.fbx";
		// }
		// else if (props.model.includes('Cushion')) {
		// 	props.model = "Cushion_3ct.fbx";
		// }
		// else if (props.model.includes('Ashoka')) {
		// 	props.model = "Ashoka_3ct.fbx";
		// }

		if (props.model.includes('Fred')) {
			props.model = "Fred_1ct.glb";
		}
		else if (props.model.includes('Oval')) {
			props.model = "Oval_1ct.glb";
		}
		else if (props.model.includes('Radiant')) {
			props.model = "Radiant_1ct.glb";
		}
		else if (props.model.includes('Cushion')) {
			props.model = "Cushion_3ct.glb";
		}


		let modelName = './assets/models/stones/' + props.model;
		modelName.replace(' ', '');

		let stlName = './assets/models/' + props.model;

		let stl = new THREE.BufferGeometry;
		let fbx;
		fbx = useGLTF(modelName as string);
		//fbx = useFBX(modelName as string);


		let geom = [] as THREE.BufferGeometry[]; //new THREE.BufferGeometry;
		if (fbx) {
			fbx.scene.traverse((item) => {
				if (item.type == "Mesh") {
					const child = item as unknown as THREE.Mesh;
					child.geometry.computeVertexNormals();
					geom.push(child.geometry);
				}
			});
		}
		props.state.scene = useThree((state) => state.gl);
		props.state.scene.setClearColor(0xffffff, 0);
		props.state.camera = useThree((state) => state.camera);
		const gl2 = useThree((gl) => gl.gl.getContext());
		gl2.pixelStorei(gl2.UNPACK_COLORSPACE_CONVERSION_WEBGL, gl2.NONE);

		let position = new THREE.Vector3(0, 0, 0);
		let scaleFactor = 1.0;
		let rotX, rotY, rotZ = 0;

		if (props.model == 'Fred_1ct.glb') {
			rotX = -Math.PI;
			rotY = Math.PI;

			if (props.state.size == 1) {
				position.set(0, 22.5, 0);
				scaleFactor = 0.65;
			}
			if (props.state.size == 2) {
				position.set(0, 23, 0);
				scaleFactor = 0.85;
			}
			else if (props.state.size == 3) {
				position.set(0, 23.5, 0);
				scaleFactor = 1.025;
			}
			else if (props.state.size == 4) {
				position.set(0, 24, 0);
				scaleFactor = 1.05;
			}
			else if (props.state.size == 5) {
				position.set(0, 24.5, 0);
				scaleFactor = 1.1;
			}
			else if (props.state.size == 6) {
				position.set(0, 24.7, 0);
				scaleFactor = 1.18;
			}
		}
		else if (props.model == 'Oval_1ct.glb') {
			rotX = -Math.PI;
			rotY = Math.PI;

			if (props.state.size == 1) {
				position.set(0, 22, 0);
				scaleFactor = 1;
			}
			if (props.state.size == 2) {
				position.set(0, 22.7, 0);
				scaleFactor = 1.28;
			}
			else if (props.state.size == 3) {
				position.set(0, 23.15, 0);
				scaleFactor = 1.45;
			}
			else if (props.state.size == 4) {
				position.set(0, 23.75, 0);
				scaleFactor = 1.58;
			}
			else if (props.state.size == 5) {
				position.set(0, 23.95, 0);
				scaleFactor = 1.72;
			}
			else if (props.state.size == 6) {
				position.set(0, 24.15, 0);
				scaleFactor = 1.8;
			}
		}
		else if (props.model == 'Radiant_1ct.glb') {
			rotX = -Math.PI;
			rotY = Math.PI;

			if (props.state.size == 1) {
				position.set(0, 22.1, 0);
				scaleFactor = 0.9;
			}
			if (props.state.size == 2) {
				position.set(0, 22.75, 0);
				scaleFactor = 1.2;
			}
			else if (props.state.size == 3) {
				position.set(0, 23.2, 0);
				scaleFactor = 1.39;
			}
			else if (props.state.size == 4) {
				position.set(0, 23.55, 0);
				scaleFactor = 1.47;
			}
			else if (props.state.size == 5) {
				position.set(0, 23.8, 0);
				scaleFactor = 1.60;
			}
			else if (props.state.size == 6) {
				position.set(0, 24.2, 0);
				scaleFactor = 1.70;
			}
		}
		else if (props.model == 'Cushion_3ct.glb') {
			rotX = -Math.PI;
			rotY = Math.PI;

			if (props.state.size == 1) {
				position.set(0, 22.1, 0);
				scaleFactor = 0.9;
			}
			if (props.state.size == 2) {
				position.set(0, 22.75, 0);
				scaleFactor = 1.2;
			}
			else if (props.state.size == 3) {
				position.set(0, 23.6, 0);
				scaleFactor = 0.98;
			}
			else if (props.state.size == 4) {
				position.set(0, 23.55, 0);
				scaleFactor = 1.47;
			}
			else if (props.state.size == 5) {
				position.set(0, 23.8, 0);
				scaleFactor = 1.60;
			}
			else if (props.state.size == 6) {
				position.set(0, 24.2, 0);
				scaleFactor = 1.70;
			}
		}

		const scale = new THREE.Vector3(scaleFactor, scaleFactor, scaleFactor);
		const rot = new THREE.Euler(rotX, rotY, rotZ);


		// const config = useControls({
		// 	bounces: { value: 2, min: 0, max: 8, step: 0.01 },
		// 	aberrationStrength: { value: 0.02, min: 0, max: 0.1, step: 0.01 },
		// 	ior: { value: 6.2, min: 0, max: 10, step: 0.01 },
		// 	fresnel: { value: 1, min: 0, max: 1, step: 0.01 },
		// 	color: 'white',
		// 	fastChroma: false
		// });

		let textureURL = './assets/images/env10.jpg';
		if (props.model.includes('Emerald')) textureURL = './assets/images/env12.jpg';

		const texture = useTexture(textureURL);

		// console.log("=========PROPS:::");
		// console.log(props.config);

		let DiamondReal = () => (
			< >
				{
					geom.map((element) => (
						< mesh key={element.uuid} name={'Diamond'} geometry={element} position={position} scale={scale} rotation={rot} >
							<MeshRefractionMaterial envMap={texture} {...props.config} toneMapped={false} />
						</mesh >
					))
				}
			</>
		)

		let DiamondSketch = () => (
			< >
				{
					geom.map((element) => (
						< mesh key={element.uuid} name={'Diamond'} geometry={element} position={position} scale={scale} rotation={rot} >
							<MeshRefractionMaterial envMap={texture} {...props.config} toneMapped={true} />
							<Edges scale={1.001} color="#777777" />
						</mesh >
					))
				}
			</>
		)

		return (
			<group>
				{props.state.sketch ? (
					<DiamondSketch />
				) : (
					<DiamondReal />
				)}
			</group >
		)
	}

	DiamondBasket({ ...props }) {

		// console.log(props.model);
		if (!props.model || props.model == '')
			return (<></>);

		let modelName = './assets/models/stones/' + props.model;
		modelName.replace(' ', '');

		let fbx;
		fbx = useGLTF(modelName as string);


		let geom = [] as THREE.BufferGeometry[]; //new THREE.BufferGeometry;
		if (fbx) {
			fbx.scene.traverse((item) => {
				if (item.type == "Mesh") {
					const child = item as unknown as THREE.Mesh;
					geom.push(child.geometry);
				}
			});
		}

		let textureURL = './assets/images/env10.jpg';
		if (props.model.includes('Emerald')) textureURL = './assets/images/env12.jpg';

		let RefractionMaterial = (props: JSX.IntrinsicAttributes) => {
			const bvh = React.useMemo(() => new MeshBVHUniformStruct(), []);
			const res = new THREE.Vector2(2, 2);
			//@ts-ignore
			return <MeshRefractionMaterial bvh={bvh} resolution={res} {...props} />
		}


		let config = {
			bounces: 1,
			aberrationStrength: 0.02,
			ior: 2.8,
			fresnel: 1,
			color: 'white',
			fastChroma: false
		};

		if (modelName.includes("Rusella")) {
			//console.log("Rusella!");
			config.bounces = 2;
			config.ior = 5.8;
			config.aberrationStrength = 0.02;
			config.fastChroma = false;
		}
		// let config = useControls({
		// 	bounces: { value: 2, min: 0, max: 8, step: 1 },
		// 	aberrationStrength: { value: 0.02, min: 0, max: 0.1, step: 0.01 },
		// 	ior: { value: 6.2, min: 0, max: 10 },
		// 	fresnel: { value: 1, min: 0, max: 1 },
		// 	color: 'white',
		// 	fastChroma: false
		// });

		const texture = useTexture(textureURL);
		let DiamondReal = () => (
			< >
				{
					geom.map((element) => (
						< mesh geometry={element} >
							<RefractionMaterial envMap={texture} {...config} toneMapped={false} />
						</mesh >
					))
				}
			</>
		)

		let DiamondSketch = () => (
			< >
				{
					geom.map((element) => (
						< mesh key={element.uuid} name={'Diamond'} geometry={element} >
							<MeshRefractionMaterial envMap={texture} {...props.config} toneMapped={true} />
							<Edges scale={1.001} color="#777777" />
						</mesh >
					))
				}
			</>
		)

		let needsRotation = (name: string) => {
			// console.log(name);
			if (name == 'Radiant_3ct_Pave_Basket.glb' || name == 'Emerald_3ct_Pave_Basket.glb' || name == 'Oval_3ct_Pave_Basket.glb' ||
				name == 'Ashoka_3ct_Pave_Basket.glb' || name == 'Cushion_3ct_Pave_Basket.glb' || name == 'Classic_Ashoka_Halo_Basket.glb' ||
				name == 'Classic_Cushion_Halo_Basket.glb' || name == 'Classic_Emerald_Halo_Basket.glb' || name == 'Classic_Oval_Halo_Basket.glb' ||
				name == 'Test_Basket.glb' ||
				name == 'Ashoka_Rusella_Mil_Basket.glb' || name == 'Cushion_Rusella_Mil_Basket.glb' || name == 'Emerald_Rusella_Mil_Basket.glb' || name == 'Fred_Rusella_Mil_Basket.glb' || name == 'Oval_Rusella_Mil_Basket.glb' ||
				name == 'Radiant_Rusella_Mil_Basket.glb' || name == 'Round_Rusella_Mil_Basket.glb' ||
				name == 'Ashoka_Rusella_NoMil_Basket.glb' || name == 'Cushion_Rusella_NoMil_Basket.glb' || name == 'Emerald_Rusella_NoMil_Basket.glb' || name == 'Fred_Rusella_NoMil_Basket.glb' || name == 'Oval_Rusella_NoMil_Basket.glb' ||
				name == 'Radiant_Rusella_NoMil_Basket.glb' || name == 'Round_Rusella_NoMil_Basket.glb' ||
				name == 'Cushion_Rusella_NoMil_Basket_Compass.glb' || name == 'Oval_Rusella_NoMil_Basket_Compass.glb' ||
				name == 'Radiant_Rusella_NoMil_Basket_Compass.glb' || name == 'Fred_Rusella_NoMil_Basket_6Prong.glb' || name == 'Round_Rusella_NoMil_Basket_6Prong.glb' ||
				name == 'Cushion_Rusella_Mil_Basket_Compass.glb' || name == 'Oval_Rusella_Mil_Basket_Compass.glb' ||
				name == 'Radiant_Rusella_Mil_Basket_Compass.glb' || name == 'Fred_Rusella_Mil_Basket_6Prong.glb' || name == 'Round_Rusella_Mil_Basket_6Prong.glb' ||
				name.includes("_Split_") || name.includes("_Pave_") ||
				name == 'Floating_Ashoka_3ct_Halo.glb' || name == 'Floating_Cushion_3ct_Halo.glb' ||
				name == 'Floating_Emerald_3ct_Halo.glb' || name == 'Floating_Fred_3ct_Halo.glb' || name == 'Floating_Oval_3ct_Halo.glb' ||
				name == 'Floating_Radiant_3ct_Halo.glb' || name == 'Floating_Round_3ct_Halo.glb' ||
				name == 'Classic_Ashoka_Halo_Basket.glb' || name == 'Classic_Cushion_Halo_Basket.glb' ||
				name == 'Classic_Emerald_Halo_Basket.glb' || name == 'Classic_Fred_Halo_Basket.glb' || name == 'Classic_Oval_Halo_Basket.glb' ||
				name == 'Classic_Radiant_Halo_Basket.glb' || name == 'Classic_Round_Halo_Basket.glb')
				return true;

			return false;
		}

		return (
			<group rotation={[0, needsRotation(props.model) ? 1.57 : 0.0, 0]}>
				{props.state.sketch ? (
					<DiamondSketch />
				) : (
					<DiamondReal />
				)}
			</group >
		)
	}

	DiamondShoulder({ ...props }) {

		// console.log(props.model);
		if (!props.model || props.model == '')
			return (<></>);

		let modelName = './assets/models/stones/' + props.model;
		modelName.replace(' ', '');

		let fbx;
		fbx = useGLTF(modelName as string);


		let geom = [] as THREE.BufferGeometry[]; //new THREE.BufferGeometry;
		if (fbx) {
			fbx.scene.traverse((item: THREE.Object3D) => {
				if (item.type == "Mesh") {
					const child = item as unknown as THREE.Mesh;
					geom.push(child.geometry);
				}
			});
		}

		let textureURL = './assets/images/env10.jpg';
		if (props.model.includes('Emerald')) textureURL = './assets/images/env12.jpg';

		let RefractionMaterial = (props: JSX.IntrinsicAttributes) => {
			const bvh = React.useMemo(() => new MeshBVHUniformStruct(), []);
			const res = new THREE.Vector2(2, 2);
			//@ts-ignore
			return <MeshRefractionMaterial bvh={bvh} resolution={res} {...props} />
		}

		let config = {
			bounces: 1,
			aberrationStrength: 0.02,
			ior: 2.8,
			fresnel: 1,
			color: 'white',
			fastChroma: false
		};

		if (modelName.includes("Rusella")) {
			//console.log("Rusella!");
			config.bounces = 2;
			config.ior = 5.8;
			config.aberrationStrength = 0.02;
			config.fastChroma = false;
		}

		//console.log(config);

		// let config = useControls({
		// 	bounces: { value: 2, min: 0, max: 8, step: 1 },
		// 	aberrationStrength: { value: 0.02, min: 0, max: 0.1, step: 0.01 },
		// 	ior: { value: 6.2, min: 0, max: 10 },
		// 	fresnel: { value: 1, min: 0, max: 1 },
		// 	color: 'white',
		// 	fastChroma: false
		// });

		const texture = useTexture(textureURL);
		let DiamondReal = () => (
			< >
				{
					geom.map((element) => (
						< mesh geometry={element} >
							<RefractionMaterial envMap={texture} {...config} faces={THREE.DoubleSide} toneMapped={false} />
						</mesh >
					))
				}
			</>
		)

		let DiamondSketch = () => (
			< >
				{
					geom.map((element) => (
						< mesh key={element.uuid} name={'Diamond'} geometry={element} >
							<MeshRefractionMaterial envMap={texture} {...props.config} toneMapped={true} />
							<Edges scale={1.001} color="#777777" />
						</mesh >
					))
				}
			</>
		)

		let RotationY = (name: string) => {
			// console.log(name);
			if (name == 'Cushion_3ct_Bezel_Shoulder.glb' || name == 'Radiant_3ct_Bezel_Shoulder.glb' || name == 'Oval_3ct_Bezel_Shoulder.glb' ||
				name == 'Emerald_3ct_Bezel_Shoulder.glb' || name == 'Round_3ct_Pave_Shoulder.glb' || name == 'Cushion_3ct_Pave_Shoulder.glb' ||
				name == 'Radiant_3ct_Pave_Shoulder.glb' || name == 'Oval_3ct_Pave_Shoulder.glb' || name == 'Emerald_3ct_Pave_Shoulder.glb' ||
				name == 'Ashoka_3ct_Pave_Shoulder.glb' || name == 'Fred_3ct_Pave_Shoulder.glb' || name == 'Classic_Ashoka_Halo_Shoulder.glb' || name == 'Classic_Emerald_Compass_Shoulder.glb' ||
				name == 'Classic_Cushion_Halo_Shoulder.glb' || name == 'Classic_Emerald_Halo_Shoulder.glb' || name == 'Classic_Fred_Halo_Shoulder.glb' ||
				name == 'Classic_Oval_Halo_Shoulder.glb' || name == 'Classic_Radiant_Halo_Shoulder.glb' || name == 'Classic_Round_Halo_Shoulder.glb' ||
				name == 'Classic_Ashoka_NoHalo_Shoulder.glb' ||
				name == 'Classic_Cushion_NoHalo_Shoulder.glb' || name == 'Classic_Emerald_NoHalo_Shoulder.glb' || name == 'Classic_Fred_NoHalo_Shoulder.glb' ||
				name == 'Classic_Oval_NoHalo_Shoulder.glb' || name == 'Classic_Radiant_NoHalo_Shoulder.glb' || name == 'Classic_Round_NoHalo_Shoulder.glb' ||
				name == 'Test_Shoulders.glb' ||
				name == 'Round_Split.glb' || name == 'Radiant_Split.glb' || name == 'Oval_Split.glb' || name == 'Fred_Split.glb' || name == 'Emerald_Split.glb' ||
				name == 'Cushion_Split.glb' || name == 'Ashoka_Split.glb' ||
				name == 'Ashoka_Split_Pave_Shoulder.glb' || name == 'Cushion_Split_Pave_Shoulder.glb' || name == 'Emerald_Split_Pave_Shoulder.glb' || name == 'Fred_Split_Pave_Shoulder.glb' || name == 'Oval_Split_Pave_Shoulder.glb' ||
				name == 'Radiant_Split_Pave_Shoulder.glb' || name == 'Round_Split_Pave_Shoulder.glb' ||
				name == 'Ashoka_Rusella_Mil_Shoulder.glb' || name == 'Cushion_Rusella_Mil_Shoulder.glb' || name == 'Emerald_Rusella_Mil_Shoulder.glb' || name == 'Fred_Rusella_Mil_Shoulder.glb' || name == 'Oval_Rusella_Mil_Shoulder.glb' ||
				name == 'Radiant_Rusella_Mil_Shoulder.glb' || name == 'Round_Rusella_Mil_Shoulder.glb' ||
				name == 'Ashoka_Rusella_NoMil_Shoulder.glb' || name == 'Cushion_Rusella_NoMil_Shoulder.glb' || name == 'Emerald_Rusella_NoMil_Shoulder.glb' || name == 'Fred_Rusella_NoMil_Shoulder.glb' || name == 'Oval_Rusella_NoMil_Shoulder.glb' ||
				name == 'Radiant_Rusella_NoMil_Shoulder.glb' || name == 'Round_Rusella_NoMil_Shoulder.glb' ||
				name.includes("_Split_") || name.includes("_Pave_") || name.includes("3Stone"))
				return 1.57;

			return 0;
		}

		let RotationX = (name: string) => {
			if (name == 'Round_3ct_NoHalo_Shoulder.glb' || name == 'Cushion_3ct_NoHalo_Shoulder.glb' ||
				name == 'Radiant_3ct_NoHalo_Shoulder.glb' || name == 'Oval_3ct_NoHalo_Shoulder.glb' || name == 'Emerald_3ct_NoHalo_Shoulder.glb' ||
				name == 'Ashoka_3ct_NoHalo_Shoulder.glb' || name == 'Fred_3ct_NoHalo_Shoulder.glb')
				return -1.57;
			return 0;
		}

		let RotationZ = (name: string) => {
			if (name == 'Round_3ct_NoHalo_Shoulder.glb' || name == 'Cushion_3ct_NoHalo_Shoulder.glb' ||
				name == 'Radiant_3ct_NoHalo_Shoulder.glb' || name == 'Oval_3ct_NoHalo_Shoulder.glb' || name == 'Emerald_3ct_NoHalo_Shoulder.glb' ||
				name == 'Ashoka_3ct_NoHalo_Shoulder.glb' || name == 'Fred_3ct_NoHalo_Shoulder.glb')
				return -1.57;
			return 0;
		}

		return (
			<group rotation={[RotationX(props.model), RotationY(props.model), RotationZ(props.model)]}>
				{props.state.sketch ? (
					<DiamondSketch />
				) : (
					<DiamondReal />
				)}
			</group >
		)
	}

	Basket({ ...props }) {
		let basketName = './assets/models/baskets/' + props.model;

		let fbx;
		fbx = useGLTF(basketName as string);
		// console.log(fbx);

		if (fbx) {
			fbx.scene.position.set({ ...props }.position[0], { ...props }.position[1], { ...props }.position[2]);
			fbx.scene.scale.set({ ...props }.scale[0], { ...props }.scale[1], { ...props }.scale[2]);

			props.state.model = fbx.scene;
			fbx.scene.name = "Basket";

			let geom = new THREE.BufferGeometry();
			fbx.scene.traverse((obj) => {
				if (obj.type == "Group") {
					obj.visible = true;
				}

				if (obj.type == "Mesh") {

					let material = (obj as THREE.Mesh).material as MeshPhysicalMaterial;
					material.color = new THREE.Color(props.color.r, props.color.g, props.color.b);
					material.roughness = 0;
					material.metalness = 1;
					if (props.state.sketch) {
						material.roughness = 0.5;
						material.metalness = 0;
					}
					(obj as THREE.Mesh).material = material;

					const child = obj as unknown as THREE.Mesh;
					geom = child.geometry;
				}
			});

			return <primitive object={fbx.scene}>
				<Edges />
			</primitive>
		}
		else {
			return <></>;
		}

	}

	Shoulder({ ...props }) {
		let shoulderName = './assets/models/shoulders/' + props.model;
		let fbx: GLTF;
		fbx = useGLTF(shoulderName as string);

		if (fbx) {
			fbx.scene.position.set({ ...props }.position[0], { ...props }.position[1], { ...props }.position[2]);
			fbx.scene.scale.set({ ...props }.scale[0], { ...props }.scale[1], { ...props }.scale[2]);

			props.state.model = fbx.scene;
			fbx.scene.name = "Shoulder";

			let milgrainTexture = new THREE.Texture;

			if (props.milgrainName)
				milgrainTexture = useTexture(props.milgrainName as string) as unknown as THREE.Texture;

			fbx.scene.traverse((obj) => {
				if (obj.type == "Group") {
					obj.visible = true;
				}

				if (obj.type == "Mesh") {

					let material = (obj as THREE.Mesh).material as MeshPhysicalMaterial;
					material.color = new THREE.Color(props.color.r, props.color.g, props.color.b);
					material.roughness = 0;
					material.metalness = 1;

					if (props.state.sketch) {
						material.roughness = 0.5;
						material.metalness = 0;
					}

					if (props.milgrainName)
						material.map = milgrainTexture;

					(obj as THREE.Mesh).material = material;
				}
			});

			return <primitive object={fbx.scene}>
				<Edges />
			</primitive>
		}
		else return <></>;
	}

	Band({ ...props }) {
		let bandName = './assets/models/bands/' + props.model;
		let fbx;
		fbx = useGLTF(bandName as string);

		if (fbx) {
			fbx.scene.position.set({ ...props }.position[0], { ...props }.position[1], { ...props }.position[2]);
			fbx.scene.scale.set({ ...props }.scale[0], { ...props }.scale[1], { ...props }.scale[2]);

			props.state.model = fbx.scene;
			fbx.scene.name = "Band";

			fbx.scene.traverse((obj) => {
				if (obj.type == "Group") {
					obj.visible = true;
				}

				if (obj.type == "Mesh") {
					let material = (obj as THREE.Mesh).material as MeshPhysicalMaterial;
					material.color = new THREE.Color(props.color.r, props.color.g, props.color.b);
					material.roughness = 0;
					material.metalness = 1;

					if (props.state.sketch) {
						material.roughness = 0.5;
						material.metalness = 0;
					}

					(obj as THREE.Mesh).material = material;
				}
			});

			return <primitive object={fbx.scene}>
				<Edges />
			</primitive>
		}
		else return <></>;
	}

	mapName(name: string) {
		if (name === "Kwiat Round™")
			return "Round";
		else if (name === "Kwiat Cushion™")
			return "Cushion";
		else if (name === "Kwiat Emerald Cut™")
			return "Emerald";
		else if (name === "Kwiat Radiant™")
			return "Radiant";
		else if (name === "Kwiat Oval™")
			return "Oval";
		else if (name === "ASHOKA®")
			return "Ashoka";
		else if (name === "Fred Leighton Round™")
			return "Fred";
		else
			return "";
	}

	loader() {
		return <Html center><div id='SmallLoader'> This is a loader</div></Html>;
	}


	render() {
		let scaleBasket = [1000, 1000, 1000];
		let scaleShoulder = [1000, 1000, 1000];
		let scale = [1000, 1000, 1000];
		const color = this.metal[this.state.colorSelected];

		this.updateURL();
		this.calculatePrice();

		let diamondName, diamondPaveShoulder, diamondPaveBasket, basketName, shoulderName, bandName;
		let config = {
			ior: 0,
			bounces: 0,
			fresnel: 0,
			aberrationStrength: 0,
			fastChroma: false
		};

		//console.log(this.state);

		if (this.state.diamond == '3Stone_Ashoka') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Ashoka.glb';
			diamondPaveShoulder = '3Stone_Ashoka.glb';
			diamondPaveBasket = '';
			diamondName = "Ashoka_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Cushion_HalfMoon') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Cushion_HalfMoon.glb';
			diamondPaveShoulder = '3Stone_Cushion_HalfMoon.glb';
			diamondPaveBasket = '';
			diamondName = "Cushion_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Cushion_HalfMoon_Tapered') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Cushion_HalfMoon_Tapered.glb';
			diamondPaveShoulder = '3Stone_Cushion_HalfMoon_Tapered.glb';
			diamondPaveBasket = '';
			diamondName = "Cushion_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Emerald_StepCut_Trapezoid') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_' + this.state.diamond + '_' + this.state.basket + '.glb';
			diamondPaveShoulder = '3Stone_' + this.state.diamond + '_' + this.state.basket + '.glb';
			diamondPaveBasket = '';
			diamondName = "Emerald_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else if (this.state.diamond == '3Stone_Emerald_StepCut_Trapezoid2') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Emerald_StepCut_Trapezoid2.glb';
			diamondPaveShoulder = '3Stone_Emerald_StepCut_Trapezoid2.glb';
			diamondPaveBasket = '';
			diamondName = "Emerald_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else if (this.state.diamond == '3Stone_Emerald_Tapered_Bag') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Emerald_Tapered_Bag.glb';
			diamondPaveShoulder = '3Stone_Emerald_Tapered_Bag.glb';
			diamondPaveBasket = '';
			diamondName = "Emerald_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else if (this.state.diamond == '3Stone_Oval_HalfMoon') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Oval_HalfMoon.glb';
			diamondPaveShoulder = '3Stone_Oval_HalfMoon.glb';
			diamondPaveBasket = '';
			diamondName = "Oval_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else if (this.state.diamond == '3Stone_Oval_Tapered_Bag') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Oval_Tapered_Bag.glb';
			diamondPaveShoulder = '3Stone_Oval_Tapered_Bag.glb';
			diamondPaveBasket = '';
			diamondName = "Oval_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else if (this.state.diamond == '3Stone_Oval_Pear') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Oval_Pear.glb';
			diamondPaveShoulder = '3Stone_Oval_Pear.glb';
			diamondPaveBasket = '';
			diamondName = "Oval_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Radiant_Tapered_Bag') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Radiant_Tapered_Bag.glb';
			diamondPaveShoulder = '3Stone_Radiant_Tapered_Bag.glb';
			diamondPaveBasket = '';
			diamondName = "Radiant_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Radiant_Trapezoid_Brilliant') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Radiant_Trapezoid_Brilliant.glb';
			diamondPaveShoulder = '3Stone_Radiant_Trapezoid_Brilliant.glb';
			diamondPaveBasket = '';
			diamondName = "Radiant_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Radiant_Trapezoid_Brilliant2') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Radiant_Trapezoid_Brilliant2.glb';
			diamondPaveShoulder = '3Stone_Radiant_Trapezoid_Brilliant2.glb';
			diamondPaveBasket = '';
			diamondName = "Radiant_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}

		else if (this.state.diamond == '3Stone_Round_Pear') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Round_Pear.glb';
			diamondPaveShoulder = '3Stone_Round_Pear.glb';
			diamondPaveBasket = '';
			diamondName = "Round_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else if (this.state.diamond == '3Stone_Round_Tapered_Bag') {
			config.ior = 5.8;
			config.bounces = 2;
			config.aberrationStrength = 0.02;
			config.fresnel = 1;
			basketName = 'empty.glb';
			shoulderName = '3Stone_Round_Tapered_Bag.glb';
			diamondPaveShoulder = '3Stone_Round_Tapered_Bag.glb';
			diamondPaveBasket = '';
			diamondName = "Round_3ct.glb";
			bandName = "empty.glb";
			scaleBasket = [1, 1, 1];
			scaleShoulder = [1, 1, 1];
		}
		else {
			let diamondType = this.state.diamond;
			//if (diamondType == "Fred") diamondType = "Round";

			let basket = this.state.basket;
			let prong = this.state.prongStyle;
			let band = this.state.band;
			let pave = this.state.pave1;

			diamondName = this.state.diamond + '_3ct' + ".glb";

			if (this.state.band == "3Stone") {

				let basket = this.state.basket;
				if (basket == "Floating") {
					if (this.state.diamond == "Round") basket = "Tapered_Bag";
					if (this.state.diamond == "Radiant") basket = "Tapered_Bag";
					if (this.state.diamond == "Oval") basket = "Tapered_Bag";
					if (this.state.diamond == "Emerald") basket = "Tapered_Bag";
					if (this.state.diamond == "Cushion") basket = "HalfMoon";
					if (this.state.diamond == "Ashoka") basket = "HalfMoon";
				}
				band = 'empty';

				scaleShoulder = [1, 1, 1];
				shoulderName = '3Stone_' + this.state.diamond + '_' + basket + '.glb';
				diamondPaveShoulder = '3Stone_' + this.state.diamond + '_' + basket + '.glb';

				basketName = 'empty.glb';
				diamondPaveBasket = '';
			}
			else if (this.state.channelStyle == "Baguettes") {
				band = 'empty';
				scaleBasket = [1, 1, 1];
				scaleShoulder = [1, 1, 1];

				shoulderName = diamondType + '_Rusella_NoMil.glb';
				diamondPaveShoulder = diamondType + '_Rusella_NoMil_Shoulder.glb';

				basketName = diamondType + '_Rusella_NoMil.glb';
				diamondPaveBasket = diamondType + '_Rusella_NoMil_Basket.glb';

				if (prong == 'Compass') {
					basketName = diamondType + '_Rusella_NoMil_Compass.glb';
					diamondPaveBasket = diamondType + '_Rusella_NoMil_Basket_Compass.glb';
				}
				else if (prong == '6Prong') {
					basketName = diamondType + '_Rusella_NoMil_6Prong.glb';
					diamondPaveBasket = diamondType + '_Rusella_NoMil_Basket_6Prong.glb';
				}

				if (this.state.milgrain == "Yes") {
					shoulderName = diamondType + '_Rusella_Mil.glb';
					diamondPaveShoulder = diamondType + '_Rusella_Mil_Shoulder.glb';

					basketName = diamondType + '_Rusella_Mil.glb';
					diamondPaveBasket = diamondType + '_Rusella_Mil_Basket.glb';

					if (prong == 'Compass') {
						basketName = diamondType + '_Rusella_Mil_Compass.glb';
						diamondPaveBasket = diamondType + '_Rusella_Mil_Basket_Compass.glb';
					}
					else if (prong == '6Prong') {
						basketName = diamondType + '_Rusella_Mil_6Prong.glb';
						diamondPaveBasket = diamondType + '_Rusella_Mil_Basket_6Prong.glb';
					}
				}
			}
			else if (basket === 'UShape') {
				scaleBasket = [1, 1, 1];
				scaleShoulder = [1, 1, 1];
				basketName = basket + '_' + diamondType + '_' + this.state.size + 'ct.glb';
				band = "empty";
				if (pave == 'Classic Single') {
					shoulderName = diamondType + '_3ct_UShape_Shoulder.glb';
					diamondPaveShoulder = diamondType + '_3ct_UShape_Shoulder.glb';
				}
				else
					shoulderName = basket + '_' + diamondType + '_' + this.state.size + 'ct_Plain.glb';
			}
			else if (basket === 'Bezel') {
				scaleBasket = [1, 1, 1];
				scaleShoulder = [1, 1, 1];
				band = "empty";
				if (pave == 'Classic Single') {
					shoulderName = diamondType + '_3ct_Bezel_Shoulder.glb';
					diamondPaveShoulder = diamondType + '_3ct_Bezel_Shoulder.glb';
				}
				else
					shoulderName = basket + '_' + diamondType + '_' + this.state.size + 'ct.glb';

				if (this.state.bezel == 'Polished')
					basketName = basket + '_' + diamondType + '_' + this.state.size + 'ct.glb';
				else
					basketName = basket + '_' + diamondType + '_' + this.state.size + 'ct_Milgrain.glb';
			}
			else if (basket === 'Floating') {
				band = 'empty';
				scaleBasket = [1, 1, 1];
				scaleShoulder = [1, 1, 1];
				//Selecting Basket
				if (prong === "6Prong") {
					basketName = diamondType + '_Plain_6Prong.glb';
				}
				else if (prong === "Compass") {
					basketName = diamondType + '_Plain_Compass.glb';
				}
				else if (prong === "ClassicClaw") {
					basketName = diamondType + '_Plain_4Prong.glb';
				}
				else if (prong === "Split") {
					basketName = diamondType + '_Plain_SplitClaw.glb';
				}

				if (this.state.halo === 'HiddenPave') {
					if (prong === "6Prong") {
						basketName = diamondType + '_Halo_6Prong.glb';
						diamondPaveBasket = diamondType + '_3ct_Pave_Basket_6Prong.glb';
					}
					else if (prong === "Compass") {
						basketName = diamondType + '_Halo_Compass.glb';
						diamondPaveBasket = diamondType + '_3ct_Pave_Basket_Compass.glb';
					}
					else if (prong === "ClassicClaw") {
						basketName = diamondType + '_Halo_4Prong.glb';
						diamondPaveBasket = diamondType + '_3ct_Pave_Basket_4Prong.glb';
					}
					else if (prong === "Split") {
						basketName = diamondType + '_Halo_SplitClaw.glb';
						diamondPaveBasket = diamondType + '_3ct_Pave_Basket_SplitClaw.glb';
					}

					if (this.state.pave2 == 'Split') {
						if (prong === "6Prong") {
							basketName = diamondType + '_Split_6Prong.glb';
							diamondPaveBasket = diamondType + '_Split_Basket_6Prong.glb';
						}
						else if (prong === "Compass") {
							basketName = diamondType + '_Split_Compass.glb';
							diamondPaveBasket = diamondType + '_Split_Basket_Compass.glb';
						}
						else if (prong === "ClassicClaw") {
							basketName = diamondType + '_Split_4Prong.glb';
							diamondPaveBasket = diamondType + '_Split_Basket_4Prong.glb';
						}
						else if (prong === "Split") {
							basketName = diamondType + '_Split_SplitClaw.glb';
							diamondPaveBasket = diamondType + '_Split_Basket_SplitClaw.glb';
						}
					}
				}

				if (this.state.halo === 'Pave') {
					//scaleBasket = [1000, 1000, 1000];
					basketName = "Floating_" + diamondType + "_3ct_Halo.glb";
					diamondPaveBasket = "Floating_" + diamondType + "_3ct_Halo.glb";
				}

				//Selecting Shoulder
				shoulderName = diamondType + '_Plain.glb';
				if (pave == 'Classic Single') {
					shoulderName = diamondType + '_Pave.glb';
					diamondPaveShoulder = diamondType + '_3ct_Pave_Shoulder.glb';
					if (this.state.halo === 'Pave') {
						shoulderName = diamondType + '_3ct_Pave.glb';
						diamondPaveShoulder = diamondType + '_Pave_Shoulder_Halo.glb';
					}

					if (this.state.pave2 == 'Split') {
						shoulderName = diamondType + '_Split.glb';
						diamondPaveShoulder = diamondType + '_Split_Pave_Shoulder.glb';
					}
				}
			}
			else if (basket === 'Classic') {
				scaleBasket = [1, 1, 1];
				scaleShoulder = [1, 1, 1];
				band = 'empty';

				//console.log(this.state);

				//Selecting Basket
				if (prong === "6Prong") {
					basketName = 'Classic_' + diamondType + '_6Prong.glb';
				}
				else if (prong === "Compass") {
					basketName = 'Classic_' + diamondType + '_Compass.glb';
				}
				else if (prong === "ClassicClaw") {
					basketName = 'Classic_' + diamondType + '_4Prong.glb';
				}

				if (this.state.halo === 'Pave') {
					basketName = 'Classic_' + diamondType + '_Halo.glb';
					diamondPaveBasket = 'Classic_' + diamondType + '_Halo_Basket.glb';
				}

				if (this.state.halo == 'HiddenPave') {
					if (prong === "6Prong") {
						basketName = 'Classic_' + diamondType + '_Pave_6Prong.glb';
						diamondPaveBasket = 'Classic_' + diamondType + '_Pave_Basket_6Prong.glb';
					}
					else if (prong === "Compass") {
						basketName = 'Classic_' + diamondType + '_Pave_Compass.glb';
						diamondPaveBasket = 'Classic_' + diamondType + '_Pave_Basket_Compass.glb';
					}
					else if (prong === "ClassicClaw") {
						basketName = 'Classic_' + diamondType + '_Pave_4Prong.glb';
						diamondPaveBasket = 'Classic_' + diamondType + '_Pave_Basket_4Prong.glb';
					}
				}

				if (this.state.pave2 == 'Split') {
					if (prong === "6Prong") {
						basketName = 'Classic_' + diamondType + '_6Prong_Split.glb';
					}
					else if (prong === "Compass") {
						basketName = 'Classic_' + diamondType + '_Compass_Split.glb';
					}
					else if (prong === "ClassicClaw") {
						basketName = 'Classic_' + diamondType + '_4Prong_Split.glb';
					}
					if (this.state.halo == 'HiddenPave') {
						if (prong === "Compass") {
							basketName = 'Classic_' + diamondType + '_Split_Compass.glb';
							diamondPaveBasket = 'Classic_' + diamondType + '_Split_Basket_Compass.glb';
						}
						else if (prong === "ClassicClaw") {
							basketName = 'Classic_' + diamondType + '_Split_4Prong.glb';
							diamondPaveBasket = 'Classic_' + diamondType + '_Split_Basket_4Prong.glb';
						}
					}

					if (this.state.halo === 'Pave') {
						basketName = 'Classic_' + diamondType + '_Split_Halo.glb';
						diamondPaveBasket = 'Classic_' + diamondType + '_Halo_Basket.glb';
					}
				}

				//Selecting Shoulder
				shoulderName = 'Classic_' + diamondType + '.glb';
				if (pave == 'Classic Single') {
					shoulderName = 'Classic_' + diamondType + '_Pave.glb';
					diamondPaveShoulder = 'Classic_' + diamondType + '_Pave_Shoulder.glb';

					if (this.state.halo === 'Pave') {
						shoulderName = 'Classic_' + diamondType + '_Pave_Halo.glb';
						diamondPaveShoulder = 'Classic_' + diamondType + '_Pave_Shoulder_Halo.glb';
					}

					if (this.state.pave2 == 'Split') {
						shoulderName = 'Classic_' + diamondType + '_Split.glb';
						diamondPaveShoulder = 'Classic_' + diamondType + '_Split_Shoulder.glb';

						if (this.state.halo === 'Pave') {
							shoulderName = 'Classic_' + diamondType + '_Split_Halo.glb';
							diamondPaveShoulder = 'Classic_' + diamondType + '_Split_Shoulder_Halo.glb';
						}
					}
				}
			}

			if (this.debug) {
				console.log("Final models are:");
				console.log("basketName: ", basketName);
				console.log("diamondPaveBasket: ", diamondPaveBasket);
				console.log("shoulderName: ", shoulderName);
				console.log("diamondPaveShoulder: ", diamondPaveShoulder);
			}
			bandName = band + '.glb';

			if (this.state.diamond.includes("Fred")) {
				config.ior = 3.96;
				config.bounces = 1.84;
				config.aberrationStrength = 0.01;
				config.fresnel = 1.0;
			}
			else if (this.state.diamond.includes("Cushion")) {
				config.ior = 2.87;
				config.bounces = 1.16;
				config.aberrationStrength = 0.02;
				config.fresnel = 0.2;
			}
			else if (this.state.diamond.includes("Round")) {
				config.ior = 5.26;
				config.bounces = 1.05;
				config.aberrationStrength = 0.01;
				config.fresnel = 1;
			}
			else if (this.state.diamond.includes('Oval')) {
				config.ior = 4.3;
				config.bounces = 2;
				config.aberrationStrength = 0.02;
				config.fresnel = 1.0;
			}
			else if (this.state.diamond.includes('Radiant')) {
				config.ior = 2.92;
				config.bounces = 1.05;
				config.aberrationStrength = 0.01;
				config.fresnel = 0.63;
			}
			else if (this.state.diamond.includes('Asscher')) {
				config.ior = 2.5;
				config.bounces = 2;
				config.aberrationStrength = 0.01;
				config.fresnel = 0.47;
			}
			else if (this.state.diamond.includes('Ashoka')) {
				config.ior = 4.49;
				config.bounces = 2.52;
				config.aberrationStrength = 0.01;
				config.fresnel = 1.0;
			}
			else if (this.state.diamond.includes('Emerald')) {
				config.ior = 2.4;
				config.bounces = 2;
				config.aberrationStrength = 0.01;
				config.fresnel = 1.0;
			}
		}

		let zoomLevel = 24

		if (window.innerHeight > 500 || screen.orientation.type != "landscape-primary") {
			zoomLevel = 15;
		}


		return (
			<>
				<Canvas shadows dpr={2} orthographic camera={{ zoom: zoomLevel, position: [0, 80, 0], fov: 20 }} gl={{ toneMapping: THREE.ReinhardToneMapping, antialias: true, precision: 'highp', preserveDrawingBuffer: true, alpha: false }}>

					{this.state.sketch ? (
						<></>
					) : (
						<Environment files={'./assets/images/env17.hdr'} />
					)}

					<ambientLight intensity={25} color={new THREE.Color(0xffffff)} ref={(ref: any) => (this.ambLight = ref)} />
					<pointLight position={[-40, -40, -10]} color={new THREE.Color(0xffffff)} ref={(ref: any) => (this.pointLight1 = ref)} intensity={100} />
					<pointLight position={[40, -40, -10]} color={new THREE.Color(0xffffff)} ref={(ref: any) => (this.pointLight2 = ref)} intensity={100} />
					<pointLight position={[-40, 40, 10]} color={new THREE.Color(0xffffff)} ref={(ref: any) => (this.pointLight3 = ref)} intensity={100} />
					<pointLight position={[40, 40, 10]} color={new THREE.Color(0xffffff)} ref={(ref: any) => (this.pointLight4 = ref)} intensity={100} />

					<React.Suspense fallback={<this.loader />} key={new Date().getTime() + 55} >
						<ErrorBoundary key={new Date().getTime() + 60}
							fallback={
								<Html position={[-5, 0, -10]}> <div style={{ color: 'red' }}> Encountered an error! {diamondName} not found in stones </div></Html>
							}
						>
							<this.Diamond position={[0, 0, 0]} scale={[1, 1, 1]} state={this.state} test={this.test} key={new Date().getTime()} config={config} model={diamondName} />
							<this.DiamondShoulder position={[0, 0, 0]} scale={[1, 1, 1]} state={this.state} test={this.test} key={new Date().getTime() + 100} model={diamondPaveShoulder} />
							<this.DiamondBasket position={[0, 0, 0]} scale={[1, 1, 1]} state={this.state} test={this.test} key={new Date().getTime() + 1000} model={diamondPaveBasket} />
						</ErrorBoundary>

						<ErrorBoundary key={new Date().getTime() + 70}
							fallback={
								<Html position={[-5, 0, -10]}> <div style={{ color: 'red' }}> Encountered an error! {bandName} not found in bands </div></Html>
							}
						>
							<this.Band position={[0, 0, 0]} scale={scale} color={color} state={this.state} model={bandName + "?" + new Date().getTime() + 2020} />
						</ErrorBoundary>

						<ErrorBoundary key={new Date().getTime() + 90}
							fallback={
								<Html position={[-5, 0, -10]}> <div style={{ color: 'red' }}> Encountered an error! {shoulderName} not found in shoulders </div></Html>
							}
						>
							<this.Shoulder position={[0, 0, 0]} scale={scaleShoulder} color={color} state={this.state} model={shoulderName + "?" + new Date().getTime() + 2020} />
						</ErrorBoundary>

						<ErrorBoundary key={new Date().getTime() + 150}
							fallback={
								<Html position={[-5, 0, -10]}> <div style={{ color: 'red' }}> Encountered an error! {basketName} not found in baskets </div></Html>
							}
						>
							<this.Basket position={[0, 0, 0]} scale={scaleBasket} color={color} state={this.state} model={basketName + "?" + new Date().getTime() + 2020} />
						</ErrorBoundary>
					</React.Suspense>
					<OrbitControls target={[0, 12.5, 0]} ref={(ref: any) => (this.orbitControls = ref)} />
					<AdaptiveDpr pixelated />
					<AdaptiveEvents />
					{/* <Stats /> */}
				</Canvas >

			</>
		)
	}

}

export default ModelLoader;

